@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

::-webkit-scrollbar {
  width: 4px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f8fcfd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666666;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666666;
}

